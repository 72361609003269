import React, { useState } from 'react';

// import required modules
import fileIcon from '../../assets/architect-design.png';
import { Button } from '../../components';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useSelector } from 'react-redux';
import css from './ListingImageSwiper.module.css';
import Slider from 'react-slick';
import IconCard from '../../components/SavedCardDetails/IconCard/IconCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import IconCollection from '../../components/IconCollection/IconCollection';

const ListingImageSwiper = props => {
  const { awsFileUrls, cloudinaryUrls, currentUser } = props ?? {};
  const images =
    cloudinaryUrls && awsFileUrls ? [...cloudinaryUrls, ...awsFileUrls] : cloudinaryUrls?.length?[...cloudinaryUrls]:[];
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const settings = {
    customPaging: function(i) {
      const file = images[i];
      return (
        <div className={!currentUser ? css.blurImg1 : css.customImage}>
          {file.secure_url ? (
            file.secure_url.includes('.pdf') ? (
              <a href={file.secure_url} className={css.pdfBox}>
                {file.secure_url && (
                  <div>
                    <IconCard brand="pdf" />
                  </div>
                )}
              </a>
            ) : (
              <img src={file.secure_url} alt="fileIcon" width="50" height="50" />
            )
          ) : (
            <img src={fileIcon} alt="fileIcon" width="50" height="50" />
          )}
        </div>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: images.length > 1,
    speed: 500,
    arrows: images.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const openModal = index => {
    setModalOpen(true);
    setSelectedImageIndex(index);
    manageDisableScrolling(true); // Example: Add logic to disable scrolling
  };

  const closeModal = () => {
    setModalOpen(false);
    manageDisableScrolling(false); // Example: Add logic to enable scrolling
  };

  const navigateTo = index => {
    if (index >= 0 && index < images.length) {
      setSelectedImageIndex(index);
    }
  };
  return (
    <>
      <div className={css.previewSlider}>
        <Slider {...settings}>
          {images?.map((file, i) => {
            return (
              <div key={i} className={currentUser ? '' : css.blurImg}>
                <div className={css.sliderImage}>
                  {file.secure_url ? (
                    file.secure_url.includes('.pdf') ? (
                      <embed src={file.secure_url} alt="fileIcon" />
                    ) : (
                      <img src={file.secure_url} alt="fileIcon" />
                    )
                  ) : (
                    <img src={fileIcon} alt="fileIcon" />
                  )}
                  {currentUser ? (
                    <Button
                      onClick={() => setModalOpen(prev => !prev)}
                    >{`View Files (${images?.length})`}</Button>
                  ) : (
                    <Button onClick={() => history.push('/signup')}>{`Sign up to view`}</Button>
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      {modalOpen && (
        <div onClose={closeModal}>
          <div className={css.modalOverlay}>
            <div className={css.modalContent}>
              <button className={css.modalClose} onClick={closeModal}>
                &times;
              </button>
              <div className={css.modalImage}>
                {images[selectedImageIndex].secure_url ? (
                  images[selectedImageIndex].secure_url.includes('.pdf') ? (
                    <embed src={images[selectedImageIndex].secure_url} alt="fileIcon" />
                  ) : (
                    <img src={images[selectedImageIndex].secure_url} alt="fileIcon" />
                  )
                ) : (
                  <div className={css.fileIconModal}>
                    <img src={fileIcon} alt="fileIcon" />
                  </div>
                )}
                <div className={css.navigation}>
                <button className={css.preButton} onClick={() => navigateTo(selectedImageIndex - 1)}><IconCollection name="pre_icon"/></button>
                <button className={css.nextButton} onClick={() => navigateTo(selectedImageIndex + 1)}><IconCollection name="next_icon"/></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListingImageSwiper;
