import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';
import ListingImageSwiper from './ListingImageSwiper';

const SectionGallery = props => {
  const { currentUser, cloudinaryUrls, awsFileUrls } = props;
  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageSwiper
        currentUser={currentUser}
        cloudinaryUrls={cloudinaryUrls}
        awsFileUrls={awsFileUrls}
      />
    </section>
  );
};

export default SectionGallery;
